import * as Constants from '../constants';
import React from 'react';
import styled from '@emotion/styled';

const DramaticHeader = styled.div`
  margin-top: 3rem;
  padding: 0.4rem 0;
  border-top: 1px solid ${Constants.COLOR.BORDER};
  border-bottom: 1px solid ${Constants.COLOR.BORDER};
  background: ${Constants.COLOR.BACKGROUNDS.HEADER};
  width: 100%;
  text-align: center;
`;

export default ({ id, text }) => (
  <DramaticHeader className='dramaticHeader' id={id}>
    {text}
  </DramaticHeader>
);
