import React from 'react';

const debounce = (func, delay) => {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(this);
    }, delay);
  };
};

//  Doesn't actually return the window size (at this time), it's just used to trigger a rerender of
//  a component when the window resizes.
export default () => {
  const setWindowSize = React.useState(`${window.innerWidth}-${window.innerHeight}`)[1];

  React.useEffect(() => {
    const onResize = debounce(() => {
      setWindowSize(`${window.innerWidth}-${window.innerHeight}`);
    }, 100);

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  });
};
