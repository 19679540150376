export const COLOR = {
  BACKGROUNDS: {
    ALT: '#666666',
    HEADER: '#444444',
    INPUT: '#DDDDDD',
    PAGE: '#000000',
    SUBMIT_BTN: '#DDDDDD',
  },
  BORDER: '#999999',
  CANCEL_BTN: {
    BACKGROUND: '#444444',
    DISABLED: {
      BORDER: '#333333',
      TEXT: '#999999',
    },
    TEXT: '#FFFFFF',
  },
  CTA_BUTTON: {
    BACKGROUND: '#33AA00',
    BACKGROUND_HOVER: '#339900',
    BORDER: '#AAAAAA',
    TEXT: '#FFFFFF',
  },
  TEXT: '#FFFFFF',
  TEXT_HOVER: '#AAAAAA',
};

export const NUM_LEVELS = 5;

export const STATUS_DEFAULTS = [
  {
    color: '#339900',
    text: 'All Clear',
  },
  {
    color: '#99cc33',
    text: 'Some Issues',
  },
  {
    color: '#ffcc00',
    text: 'Trouble Brewing',
  },
  {
    color: '#ff9933',
    text: "We're in Trouble",
  },
  {
    color: '#cc3300',
    text: 'Panic!',
  },
];
