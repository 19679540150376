import * as Constants from '../../constants';
import * as types from '../../types';
import React from 'react';
import styled from '@emotion/styled';

const TextInput = styled.input`
  font-size: 1.5rem;
  padding: 2px 4px;
  background: ${Constants.COLOR.BACKGROUNDS.INPUT};
`;

const AdminName = ({ name, onChange }) => {
  return (
    <React.Fragment>
      <div>Name:</div>
      <div>
        <TextInput
          maxLength={20}
          name='label'
          onChange={onChange}
          placeholder='Name (required)'
          size={20}
          type='text'
          value={name}
        />
      </div>
    </React.Fragment>
  );
};

AdminName.propTypes = {
  name: types.string.isRequired,
  onChange: types.func.isRequired,
};

export default AdminName;
