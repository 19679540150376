import * as Constants from '../../constants';
import * as types from '../../types';
import 'firebase/firestore';
import React from 'react';
import styled from '@emotion/styled';

const Select = styled.select`
  font-size: 1.5rem;
  padding: 2px 4px;
  background: ${Constants.COLOR.BACKGROUNDS.INPUT};
`;

const AdminCurrentStatus = ({ currentStatus, levels, onChange }) => {
  const options = levels
    .slice()
    .reverse()
    .map((level, index) => {
      const val = Constants.NUM_LEVELS - index - 1;
      return (
        <option value={val} key={val} disabled={!level.active}>
          {level.text}
        </option>
      );
    });

  return (
    <React.Fragment>
      <div>Current Status:</div>
      <div>
        <Select value={currentStatus} onChange={onChange}>
          {options}
        </Select>
      </div>
    </React.Fragment>
  );
};

AdminCurrentStatus.propTypes = {
  currentStatus: types.number,
  levels: types.levels,
  onChange: types.func.isRequired,
};

export default AdminCurrentStatus;
