import * as effects from '../hooks/effects';
import * as types from '../types';
import React from 'react';
import Status from './Status';
import styled from '@emotion/styled';

const Container = styled.div`
  display: grid;
  grid-template-columns: 20px auto;
  width: 100%;
  height: 100%;
`;

const NotFound = styled.div`
  grid-column: 2;
  align-self: center;
  justify-self: center;
`;

const StatusContainer = ({ id, numCols, numRows }) => {
  const { matchFound, fetchResults } = effects.useStatusDisplay(id);

  let content;
  if (!matchFound) {
    content = matchFound === false ? <NotFound>{id} not found</NotFound> : <span></span>;
  } else {
    const { currentStatus, label, levels, statusText } = fetchResults;
    const props = { currentStatus, label, levelColors: levels, numCols, numRows, statusText };
    content = <Status {...props} />;
  }

  return <Container>{content}</Container>;
};

StatusContainer.propTypes = {
  id: types.string,
  numCols: types.number,
  numRows: types.number,
};

export default StatusContainer;
