import * as types from '../../types';
import 'firebase/firestore';
import React from 'react';
import Status from '../Status';
import styled from '@emotion/styled';

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  font-family: sans-serif;
  justify-items: center;
  margin-top: 2rem;
`;

const Sample = styled.div`
  display: grid;
  grid-template-columns: 20px auto;
  width: 35vw;
  height: 17vw;
  margin-bottom: 3rem;
`;

const AdminSamples = ({ label, levels }) => {
  const renderSamples = () => {
    const activeLevels = levels.filter(level => level.active).reverse();
    const colors = activeLevels.map(level => level.color).reverse();
    return activeLevels.map((level, index) => (
      <Sample key={index}>
        <Status
          currentStatus={activeLevels.length - index - 1}
          label={label}
          levelColors={colors}
          statusText={level.text}
          numRows={3}
          numCols={2.5}
        />
      </Sample>
    ));
  };

  return <Container>{renderSamples()}</Container>;
};

AdminSamples.propTypes = {
  label: types.string,
  levels: types.levels,
};

export default AdminSamples;
