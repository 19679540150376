import App from './App';
import firebase from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom';

firebase.initializeApp({
  apiKey: 'AIzaSyBX08Th_fCca56UeVVNY44aKy2SFxVwEwE',
  databaseURL: 'https://devrig-14893.firebaseio.com',
  projectId: 'devrig-14893',
  appId: '1:1088224706767:web:cca92a35f1b05c4dfa37ba',
});

ReactDOM.render(<App />, document.getElementById('root'));
