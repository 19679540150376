import firebase from 'firebase/app';
import 'firebase/firestore';
import React from 'react';

export default id => {
  const [matchFound, setMatchFound] = React.useState(undefined);
  const [fetchResults, setFetchResults] = React.useState({});

  React.useEffect(() => {
    if (id) {
      try {
        const db = firebase.firestore();
        db.collection('statusDisplay')
          .doc(id)
          .onSnapshot(doc => {
            if (doc.exists) {
              const { currentStatus, label, levels, statusText } = doc.data();
              setFetchResults({
                currentStatus,
                label,
                levels,
                statusText,
              });
              setMatchFound(true);
            } else {
              setMatchFound(false);
            }
          });
      } catch (e) {
        console.log(e);
        setMatchFound(false);
      }
    }
  }, [id]);

  return { matchFound, fetchResults };
};
