import * as Constants from '../../constants';
import * as types from '../../types';
import 'firebase/firestore';
import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  grid-column: 2;
`;

const BaseButton = styled.button`
  font-family: sans-serif;
  font-size: 1.5rem;
  margin-top: 1rem;
  padding: 0.25rem 1rem;
  border-radius: 0.25rem;

  :hover {
    cursor: pointer;
  }
`;

const CancelButton = styled(BaseButton)`
  margin-left: 2rem;
  background: ${Constants.COLOR.CANCEL_BTN.BACKGROUND};
  color: ${Constants.COLOR.CANCEL_BTN.TEXT};

  :disabled {
    color: ${Constants.COLOR.CANCEL_BTN.DISABLED.TEXT};
    border-color: ${Constants.COLOR.CANCEL_BTN.DISABLED.BORDER};
  }
`;

const SubmitButton = styled(BaseButton)`
  background: ${Constants.COLOR.BACKGROUNDS.SUBMIT_BTN};
`;

const AdminCommands = ({ changesMade, onCancel, onSave }) => (
  <Container>
    <SubmitButton disabled={!changesMade} onClick={onSave}>
      Save Changes
    </SubmitButton>
    <CancelButton disabled={!changesMade} onClick={onCancel}>
      Cancel
    </CancelButton>
  </Container>
);

AdminCommands.propTypes = {
  changesMade: types.bool.isRequired,
  onCancel: types.func.isRequired,
  onSave: types.func.isRequired,
};

export default AdminCommands;
