import * as Constants from '../constants';
import DramaticHeader from '../Components/DramaticHeader';
import firebase from 'firebase/app';
import 'firebase/firestore';
import React from 'react';
import styled from '@emotion/styled';

const BPWidth = 768;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${Constants.COLOR.BACKGROUNDS.PAGE};
  color: ${Constants.COLOR.TEXT};
  font-family: sans-serif;
  overflow: scroll;
`;

const HeadLine = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 30vmax;
  background: ${Constants.COLOR.BACKGROUNDS.ALT};
  border-bottom: 1px solid ${Constants.COLOR.BORDER};

  @media (max-width: ${BPWidth}px) {
    grid-template-columns: 100%;
    height: auto;
    padding-top: 2rem;
  }
`;

const TagLine = styled.div`
  text-align: center;
  font-size: 4vw;
  font-weight: bold;
  padding: 0 2rem;

  @media (max-width: ${BPWidth}px) {
    padding-bottom: 1rem;
  }
`;

const SecondaryTagLine = styled.div`
  text-align: center;
  font-size: calc(1rem + 1vw);
  font-weight: bold;
  padding: 2rem;
`;

const LowLevelTagLine = styled.div`
  font-size: calc(0.8rem + 0.5vw);
  font-weight: bold;
  padding: 3rem 25vw 1rem 25vw;
  text-align: justify;
`;

const SampleScreen = styled.div`
  text-align: center;
`;

const Features = styled.div`
  width: 100vw;
`;

const CallOuts = styled.div`
  height: 20rem;
  width: calc(100vw - 26rem);
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-column-gap: 7rem;
  padding: 0 6rem;
  margin-top: 2rem;
  align-items: center;
  justify-items: center;

  @media (max-width: ${BPWidth}px) {
    grid-template-columns: auto;
    width: auto;
    height: auto;
    grid-row-gap: 2rem;
    padding: 0 20vw;
  }
`;

const Callout = styled.div`
  background: ${Constants.COLOR.BACKGROUNDS.ALT};
  height: 15rem;
  width: 100%;
  padding: 1rem;
  min-width: 10rem;
  border: 1px solid ${Constants.COLOR.BORDER};

  @media (max-width: ${BPWidth}px) {
    height: auto;
  }
`;

const CalloutImage = styled.div`
  font-size: 3rem;
  text-align: center;
`;

const CalloutHeader = styled.div`
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;

  a {
    color: ${Constants.COLOR.TEXT};
  }

  a:hover {
    color: ${Constants.COLOR.TEXT_HOVER};
  }
`;

const CalloutContent = styled.div`
  margin-top: 1rem;
  font-size: 1.25rem;
  text-align: center;
`;

const Details = styled.div`
  font-size: 1.5rem;
  margin: 5rem;

  p {
    margin: 1.5rem 1rem;
    text-align: justify;
    word-break: break-word;
  }

  a {
    color: ${Constants.COLOR.TEXT};
  }

  a:hover {
    color: ${Constants.COLOR.TEXT_HOVER};
  }

  code {
    font-size: 2rem;
  }

  .dramaticHeader {
    margin-top: 6rem;
  }
`;

const CreateButton = styled.div`
  width: 100%;
  text-align: center;
  margin: 1rem 0;

  button {
    font-size: 1.5rem;
    padding: 0.5rem 2rem;
    background: ${Constants.COLOR.CTA_BUTTON.BACKGROUND};
    border-color: ${Constants.COLOR.CTA_BUTTON.BORDER};
    border-radius: 0.5rem;
    color: ${Constants.COLOR.CTA_BUTTON.TEXT};

    :hover {
      cursor: pointer;
      background: ${Constants.COLOR.CTA_BUTTON.BACKGROUND_HOVER};
    }
  }
`;

const onCreate = async () => {
  const levels = Constants.STATUS_DEFAULTS.map(level => ({ ...level, active: true }));
  const label = 'Team Status';
  const currentStatus = 0;
  const createdAt = firebase.firestore.FieldValue.serverTimestamp();
  const db = firebase.firestore();

  try {
    const resp = await db.collection('status').add({ createdAt, currentStatus, label, levels });
    window.location = `/a/${resp.id}`;
  } catch (e) {
    console.log(e);
    alert('Sorry, it looks like something went horribly wrong');
  }
};

const renderCTA = () => (
  <CreateButton>
    <button onClick={onCreate}>Create Yours Now</button>
  </CreateButton>
);

export default () => (
  <Container>
    <HeadLine>
      <TagLine>Easily Display Your Status</TagLine>
      <SampleScreen>
        <img src='sample.png' width='80%' alt='Sample screen shot of status dashboard' />
      </SampleScreen>
    </HeadLine>
    <Features>
      <SecondaryTagLine>It's like a build light for your non-automated systems.</SecondaryTagLine>
      {renderCTA()}
      <CallOuts>
        <Callout>
          <CalloutImage>
            <span role='img' aria-label='race horse'>
              🏇
            </span>
          </CalloutImage>
          <CalloutHeader>
            <a href='#quick'>Quick</a>
          </CalloutHeader>
          <CalloutContent>Be radiating status to others with one click.</CalloutContent>
        </Callout>
        <Callout>
          <CalloutImage>
            <span role='img' aria-label='toolbox'>
              🧰
            </span>
          </CalloutImage>
          <CalloutHeader>
            <a href='#custom'>Customizable</a>
          </CalloutHeader>
          <CalloutContent>Choose your own text, colors, and levels</CalloutContent>
          <CalloutContent>Advanced dashboards.</CalloutContent>
        </Callout>
        <Callout>
          <CalloutImage>
            <span role='img' aria-label='bag of cash'>
              💰
            </span>
          </CalloutImage>
          <CalloutHeader>
            <a href='#free'>Free</a>
          </CalloutHeader>
          <CalloutContent>
            Create as many dashboards as you like... at <strong>no cost</strong>.
          </CalloutContent>
        </Callout>
      </CallOuts>
      <LowLevelTagLine>
        "We were looking for something like an old-school Defcon indicator to put on a TV in the engineering area to
        broadcast the overall team status. We had it up in running in about a minute."
      </LowLevelTagLine>
      <LowLevelTagLine>
        "With the advanced layout features, everyone on the team, and more importantly, people outside our team, knows
        everyone's status instantly."
      </LowLevelTagLine>
      <LowLevelTagLine>
        "This solves a real problem for us. We have all sorts of information radiators based on our build systems. But
        nothing for our "human" systems. This has solved that problem. And our organization is much better informed as a
        result."
      </LowLevelTagLine>
      {renderCTA()}
    </Features>
    <Details>
      <DramaticHeader id='quick' text='Quick' />
      <p>
        A single click is all it takes. Your status dashboard will be created instantly and you'll be taken to the page
        that allows you to manage and customize your status.
      </p>
      <p>No annoying sign ups or account creation.</p>
      <p>
        Once you create your status dashboard, you'll be given two URLs. One is used for displaying your dashboard, this
        URL can be shared publicly and can be used to broadcast your status to others. The other URL is for managing the
        settings and the current status of your dashboard, this URL should be kept private.
      </p>
      <p>
        Because we don't require, or even offer, an account sign up and we collect no personal information, you need to
        keep track of the URLs for you dashboard. We recommend copying them into a document or a password manager (since
        most of these can also manage accounts) for safe keeping.
      </p>
      {renderCTA()}

      <DramaticHeader id='custom' text='Customizable' />
      <p>
        One simple page allows you to customize your status dashboard just the way you want it. Changes are instantly
        broadcast to all displays in real-time.
      </p>
      <p style={{ textAlign: 'center' }}>
        <img src='settingsPage.png' width='60%' alt='Sample screen shot of the status dashboard settings' />
      </p>
      <p>
        Have multiple status dashboards that you want to broadcast? No problem. With advanced layouts, you can display
        multiple status dashboards on one screen in variety of custom layouts. <a href='#advancedLayout'>Read how</a>.
      </p>
      <p style={{ textAlign: 'center' }}>
        <img src='advancedLayout.png' width='60%' alt='Sample screen shot of multiple status dashboards' />
      </p>
      {renderCTA()}

      <DramaticHeader id='free' text='Free' />
      <p>Create as many status dashboards as you like. It's free. No ads. No BS.</p>
      <p>
        It's often said that if a product or service is free, then <strong>you</strong> are the product. Not here. We
        collect no personally identifiable information, we don't display ads, and there is no user tracking.
      </p>
      {renderCTA()}

      <DramaticHeader id='advancedLayout' text='Advanced Layout' />
      <p>Advanced layouts allow you to display multiple status dashboard on a single page.</p>
      <p>
        When you create your dashboard, you are given a public URL for the status display. For example,{' '}
        <code>https://devrig.com/s/abcdefg</code>. The end of the URL, after the last <code>/</code> character, contains
        the unique identifier (which we'll refer to as the <code>UID</code>) for your status page. In this case, the{' '}
        <code>UID</code> would be <code>abcdefg</code>.
      </p>
      <p>
        If you have multiple status dashboard, you can gather the <code>UID</code> for each of them, and create your own
        URL that displays them all. To do this, the URL is appended with the additional <code>UID</code> values as well
        as <code>+</code> (the plus sign) and <code>,</code> (the comma) characters to define the layout.
      </p>
      <p>
        The <code>+</code> layout character indicates that the next <code>UID</code> should be added to the same line as
        the previous <code>UID</code>.
      </p>
      <p>
        The <code>,</code> layout character indicates the the next <code>UID</code> should be placed on a separate line.
      </p>
      <p>
        By combining these layout characters with your <code>UID</code>s, you can create an endless possibility of
        layout options.
      </p>
      <p>
        As an example, consider the case where we have several status dashboards with <code>UID</code>s of{' '}
        <code>aaaaaa</code>, <code>bbbbbbb</code>, <code>ccccccc</code>, <code>ddddddd</code>, <code>eeeeeee</code>.
        with the following URL, we'll get the resulting image (in the image, the <code>UID</code>s have been added to
        each dashboard to explain the layout, they will not be shown in the actual advanced layout):
      </p>
      <p>
        <code>https://devrig.com/s/aaaaaaa+bbbbbbb,ccccccc,ddddddd+eeeeeee</code>
      </p>
      <p style={{ textAlign: 'center' }}>
        <img
          src='advancedLayoutAnnotated.png'
          width='60%'
          alt='Sample screen shot of multiple status dashboards with the layout annotated'
        />
      </p>
      <p>
        There are no technical limits as to how many status dashboards can be shown on a page. But if you try to show
        too many, they'll probably start looking &quot;less than optimal.&quot; Of course, the bigger your display, the
        more you can show before that limit is reached.
      </p>
      {renderCTA()}
    </Details>
  </Container>
);
