import * as effects from '../hooks/effects';
import * as types from '../types';
import Commands from '../Components/Admin/Commands';
import CurrentStatus from '../Components/Admin/CurrentStatus';
import DramaticHeader from '../Components/DramaticHeader';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Levels from '../Components/Admin/Levels';
import Name from '../Components/Admin/Name';
import React from 'react';
import Samples from '../Components/Admin/Samples';
import styled from '@emotion/styled';
import Usage from '../Components/Admin/Usage';

const PageContainer = styled.div`
  padding: 0 3rem 3rem 3rem;
  font-family: sans-serif;
  font-size: 1.5rem;
`;

const NotFound = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 10rem;
`;

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  grid-row-gap: 2rem;
  width: 100%;
  font-family: sans-serif;
  margin-top: 2rem;
`;

const Admin = ({ match }) => {
  const { id } = match.params;

  const [changesMade, setChangesMade] = React.useState(false);
  const { matchFound, fetchResults, setFetchResults, resetFetchResults } = effects.useStatus(id);
  const { currentStatus, label, levels } = fetchResults;

  const onSave = async () => {
    const db = firebase.firestore();
    if (id) {
      const newLevels = levels.map(level => ({ ...level, text: level.text.trim() }));
      const newLabel = label.trim();

      if (!newLabel || newLevels.some(level => !level.text)) {
        return;
      }

      try {
        await db
          .collection('status')
          .doc(id)
          .set({ currentStatus: parseInt(currentStatus), label: newLabel, levels: newLevels }, { merge: true });
        setChangesMade(false);
      } catch (e) {
        console.log(e);
        alert('Sorry, it looks like something went horribly wrong');
      }
    }
  };

  const onReset = async () => {
    await resetFetchResults();
    setChangesMade(false);
  };

  const changeState = newState => {
    setFetchResults({ ...fetchResults, ...newState });
    setChangesMade(true);
  };

  const setLevelValue = (index, key, value) => {
    levels[index][key] = value;
    changeState({ levels });
  };

  const onNameChange = event => changeState({ label: event.target.value });

  const onCurrentStatusChange = event => changeState({ currentStatus: parseInt(event.target.value) });

  const onLevelActiveChange = event => {
    const clickedLevel = parseInt(event.target.value);
    const checked = event.target.checked;
    const numChecked = levels.reduce((acc, level) => acc + (level.active ? 1 : 0), 0);
    if (numChecked <= 2 && !checked) {
      //  Must have at least two levels checked, just ignore them if they try to have fewer
      return;
    }

    levels[clickedLevel].active = checked;
    const newState = { levels };

    //  Reset the selected level if the currently selected one has been unchecked
    if (!checked && currentStatus === clickedLevel) {
      newState.currentStatus = levels.findIndex(level => level.active && level !== clickedLevel);
    }

    changeState(newState);
  };

  const onLevelColorChange = event => setLevelValue(event.target.name, 'color', event.target.value);

  const onLevelNameChange = event => setLevelValue(event.target.name, 'text', event.target.value);

  let content;
  if (!matchFound) {
    content = matchFound === false ? <NotFound>{id} not found</NotFound> : <span></span>;
  } else {
    content = (
      <React.Fragment>
        <DramaticHeader id='usage' text='Usage' />
        <Usage id={id} />
        <DramaticHeader id='settings' text='Settings' />
        <FormContainer>
          <Name name={label} onChange={onNameChange} />
          <CurrentStatus currentStatus={currentStatus} levels={levels} onChange={onCurrentStatusChange} />
          <Levels
            levels={levels}
            onCheckboxChange={onLevelActiveChange}
            onColorChange={onLevelColorChange}
            onTextChange={onLevelNameChange}
          />
          <Commands changesMade={changesMade} onCancel={onReset} onSave={onSave} />
        </FormContainer>
        <DramaticHeader id='previews' text='Previews' />
        <Samples label={label} levels={levels} />
      </React.Fragment>
    );
  }

  return <PageContainer>{content}</PageContainer>;
};

Admin.propTypes = {
  match: types.adminUrlParams,
};

export default Admin;
