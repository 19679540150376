import * as Constants from '../constants';
import * as types from '../types';
import React from 'react';
import styled from '@emotion/styled';

const Options = styled.div`
  display: grid;
`;

const Option = styled.div`
  background: ${props => props.bgColor};
  width: 100%;
  height: 100%;
`;

const StatusContainer = styled.div`
  display: grid;
  grid-template-rows: 20% 80%;
  width: 100%;
  background: ${props => props.bgColor};
  color: ${props => props.color};
  justify-items: center;
  align-items: center;
`;

const Header = styled.div`
  font-size: ${props => `${props.fontSize}px`};
  align-self: end;
`;

const CurrentStatus = styled.div`
  font-size: ${props => `${props.fontSize}px`};
  font-weight: bold;
`;

const computeTextColor = bgColor => {
  const luminance = [0.2126, 0.7152, 0.0722].reduce((result, factor, index) => {
    const colorLoc = 1 + 2 * index;
    const color = parseInt(bgColor.substring(colorLoc, colorLoc + 2), 16) / 255;
    return result + factor * (color <= 0.03928 ? color / 12.92 : Math.pow((color + 0.055) / 1.055, 2.4));
  }, 0.0);
  //  W3C recommends 0.179 as the cut-off, I prefer it quite a bit higher.
  return luminance > 0.5 ? Constants.COLOR.BACKGROUNDS.PAGE : Constants.COLOR.TEXT;
};

const computeFontSize = (availableHeight, availableWidth, text) => {
  if (!text) {
    return 32;
  }

  const optimalHeight = availableHeight / 1.15;
  const optimalWidth = (availableWidth / text.length) * 1.25;

  return Math.min(optimalHeight, optimalWidth);
};

const StatusComponent = ({ currentStatus, label, levelColors, numCols, numRows, statusText }) => {
  const renderOptions = () => {
    const reversed = levelColors.slice().reverse();
    return reversed.map(level => <Option bgColor={level} key={level} />);
  };

  const { innerHeight, innerWidth } = window;

  const headerFontSize = computeFontSize((innerHeight / numRows) * 0.15, (innerWidth / numCols) * 0.4, label);
  const statusFontSize = computeFontSize((innerHeight / numRows) * 0.5, (innerWidth / numCols) * 0.8, statusText);

  const bgColor = levelColors[currentStatus];
  const color = computeTextColor(bgColor);

  return (
    <React.Fragment>
      <Options>{renderOptions()}</Options>
      <StatusContainer bgColor={bgColor} color={color}>
        <Header fontSize={headerFontSize}>{label}</Header>
        <CurrentStatus fontSize={statusFontSize}>{statusText}</CurrentStatus>
      </StatusContainer>
    </React.Fragment>
  );
};

StatusComponent.propTypes = {
  currentStatus: types.number,
  label: types.string,
  levelColors: types.arrayOf(types.string),
  numCols: types.number,
  numRows: types.number,
  statusText: types.string,
};

export default StatusComponent;
