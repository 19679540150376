import * as Constants from '../../constants';
import * as types from '../../types';
import 'firebase/firestore';
import React from 'react';
import styled from '@emotion/styled';

const TextInput = styled.input`
  font-size: 1.5rem;
  padding: 2px 4px;
  background: ${Constants.COLOR.BACKGROUNDS.INPUT};
`;

const Level = styled.div`
  display: grid;
  grid-template-columns: 40px 100px auto;
  grid-row-gap: 1rem;
  align-items: center;
  justify-items: center;
`;

const LevelHeader = styled.div`
  font-size: 0.8rem;
`;

const CheckboxContainer = styled.div`
  align-self: normal;

  > input[type='checkbox'] {
    transform: scale(1.5);
  }
`;

const LevelText = styled.div`
  justify-self: start;
  margin-left: 1rem;
`;

const AdminLevels = ({ levels, onCheckboxChange, onColorChange, onTextChange }) => {
  const levelsRows = levels
    .slice()
    .reverse()
    .map((level, index) => {
      const val = Constants.NUM_LEVELS - index - 1;
      const { active, color, text } = level;
      return (
        <React.Fragment key={index}>
          <CheckboxContainer>
            <input checked={active} onChange={onCheckboxChange} type='checkbox' value={val} />
          </CheckboxContainer>
          <div>
            <input name={val} disabled={!active} onChange={onColorChange} type='color' value={color} />
          </div>
          <LevelText>
            <TextInput
              disabled={!active}
              maxLength={20}
              name={val}
              placeholder='Status (required)'
              onChange={onTextChange}
              size={20}
              type='text'
              value={text}
            />
          </LevelText>
        </React.Fragment>
      );
    });

  return (
    <React.Fragment>
      <div>Statuses:</div>
      <Level>
        <LevelHeader>Enabled</LevelHeader>
        <LevelHeader>Color</LevelHeader>
        <LevelText>
          <LevelHeader>Message</LevelHeader>
        </LevelText>
        {levelsRows}
      </Level>
    </React.Fragment>
  );
};

AdminLevels.propTypes = {
  levels: types.levels,
  onCheckboxChange: types.func.isRequired,
  onColorChange: types.func.isRequired,
  onTextChange: types.func.isRequired,
};

export default AdminLevels;
