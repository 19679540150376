import { BrowserRouter, Route } from 'react-router-dom';
import Admin from './Layouts/Admin';
import Dashboard from './Layouts/Dashboard';
import Landing from './Layouts/Landing';
import React from 'react';

export default () => (
  <BrowserRouter>
    <Route exact path='/' component={Landing} />
    <Route exact path='/s/:config' component={Dashboard} />
    <Route exact path='/a/:id' component={Admin} />
  </BrowserRouter>
);
