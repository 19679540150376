import firebase from 'firebase/app';
import 'firebase/firestore';
import React from 'react';

export default id => {
  const [matchFound, setMatchFound] = React.useState(undefined);
  const [fetchResults, setFetchResults] = React.useState({});

  const fetchData = React.useCallback(async () => {
    if (id) {
      try {
        const db = firebase.firestore();
        const doc = await db
          .collection('status')
          .doc(id)
          .get();

        if (doc.exists) {
          const { currentStatus, label, levels } = doc.data();
          setFetchResults({ currentStatus, label, levels });
          setMatchFound(true);
        } else {
          setMatchFound(false);
        }
      } catch (e) {
        console.log(e);
        setMatchFound(false);
      }
    }
  }, [id]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const resetFetchResults = () => fetchData();

  return { matchFound, fetchResults, setFetchResults, resetFetchResults };
};
