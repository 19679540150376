import * as Constants from '../../constants';
import * as types from '../../types';
import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  display: grid;
  width: 100%;
  font-family: sans-serif;
  margin-top: 2rem;
  grid-auto-flow: row;
  justify-items: left;
`;

const Header = styled.div`
  justify-self: center;
  margin-bottom: 1rem;
`;

const URL = styled.span`
  font-family: monospace;
  margin-left: 2rem;

  a {
    color: ${Constants.COLOR.TEXT};

    :hover {
      color: ${Constants.COLOR.TEXT_HOVER};
    }
  }
`;

const Content = styled.div`
  margin: 1rem 3rem;
  font-size: 20px;
`;

const Footnote = styled.div`
  margin: 1rem 3rem;
  font-size: 16px;
  font-weight: bold;
`;

const AdminUsage = ({ id }) => {
  const origin = window.location.origin;
  const viewUrl = `${origin}/s/${id.substr(-7)}`;
  const manageUrl = `${origin}/a/${id}`;

  return (
    <Container>
      <Header>Your status page has been created and is ready for use</Header>
      <Content>
        URL to view (public):{' '}
        <URL>
          <a href={viewUrl} target='_blank' rel='noopener noreferrer'>
            {viewUrl}
          </a>
        </URL>
      </Content>
      <Content>
        URL to manage (secret):{' '}
        <URL>
          <a href={manageUrl} target='_blank' rel='noopener noreferrer'>
            {manageUrl}
          </a>
        </URL>
      </Content>
      <Footnote>
        You must save these URLs right now. If you lose them, you won't be able to access or manage your status page.
      </Footnote>
    </Container>
  );
};

AdminUsage.propTypes = {
  id: types.string,
};

export default AdminUsage;
