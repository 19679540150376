import * as effects from '../hooks/effects';
import * as types from '../types';
import React from 'react';
import Status from '../Components/StatusContainer';
import styled from '@emotion/styled';

const PageContainer = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-row-gap: 1px;
  width: 100vw;
  height: 100vh;
  font-family: sans-serif;
`;

const PageRow = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: 2px;
  height: 100%;
  width: 100%;
`;

const Dashboard = ({ match }) => {
  effects.useWindowResize();

  //  A 2-D array of IDs to display
  const parsedIds = match.params.config.split(',').map(row => row.split('+'));
  const numRows = parsedIds.length;

  const statusContent = parsedIds.map((row, rowIndex) => {
    const numCols = row.length;
    const rowContent = row.map((col, colIndex) => {
      return <Status id={col} key={`${rowIndex}-${colIndex}`} numCols={numCols} numRows={numRows} />;
    });
    return <PageRow key={rowIndex}>{rowContent}</PageRow>;
  });

  return <PageContainer numRows={parsedIds.length}>{statusContent}</PageContainer>;
};

Dashboard.propTypes = {
  match: types.dashboardUrlParams,
};

export default Dashboard;
